"use client";

import { useEffect, useState } from "react";
import { FooterLinkProps } from "./FooterLink";
import Link from "next/link";

export default function FooterEmail(props: FooterLinkProps) {
    const [email, setEmail] = useState<string>("");

    useEffect(() => setEmail(props.href), [props.href]);

    return (
        <Link href={email}>
            {props.title}
        </Link>
    );
}
