"use client";

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion } from "framer-motion";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { LanguageOption, LANGUAGES } from "@/app/constants/languages";
import CountryFlag from "./CountryFlag";
import dynamic from "next/dynamic";

const LanguageDropdown = dynamic(
    () => import("./LanguageDropdown"), { ssr: false }
);

const LANGUAGE_SELECTOR_ID: string = "language-selector";

export default function LanguagePicker() {
    const { i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleWindowClick = useCallback((event: MouseEvent) => {
        const target: HTMLElement | null = event.target as HTMLElement;
        const button: HTMLButtonElement | null = target?.closest("button");
        if (button && button.id === LANGUAGE_SELECTOR_ID) return;
        setIsOpen(false); // Close the dropdown.
    }, []);

    useEffect(() => {
        window.addEventListener("click", handleWindowClick);
        return () => window.removeEventListener("click", handleWindowClick);
    }, [handleWindowClick]);

    const selectedLanguage: LanguageOption | undefined = LANGUAGES.find(
        (option) => option.value === i18n.language
    );

    return (
        <section className="relative">
            <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                id={LANGUAGE_SELECTOR_ID}
                aria-expanded={isOpen}
                className="flex flex-row gap-4 px-4 py-2 justify-between 
                items-center w-48 rounded-xl bg-neutral-200 
                dark:bg-neutral-800 hover:bg-neutral-300 
                dark:hover:bg-neutral-700 transition-all ease-in-out 
                duration-300"
            >
                <section className="flex flex-row items-center gap-4 truncate">
                    {selectedLanguage && (
                        <CountryFlag
                            countryCode={selectedLanguage.countryCode}
                            aria-label={`${selectedLanguage.label} flag`}
                            alt={selectedLanguage.label}
                            title={selectedLanguage.countryCode}
                            className="rounded-sm w-5 h-auto"
                        />
                    )}
                    <span
                        className="truncate text-neutral-900 
                        dark:text-neutral-100"
                    >
                        {selectedLanguage?.label}
                    </span>
                </section>
                <motion.span
                    initial={{ rotate: 0 }}
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                >
                    <ChevronDownIcon className="w-4 h-4" />
                </motion.span>
            </button>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <LanguageDropdown />
                    </motion.div>
                )}
            </AnimatePresence>
        </section>
    );
}
