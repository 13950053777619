"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function FooterCopyright() {
    const { t } = useTranslation(Translations.FOOTER);
    const currentYear: number = new Date().getFullYear();

    return (
        <p 
            className="sm:inline-flex flex-col text-balance text-sm 
            text-neutral-700 dark:text-neutral-300 text-center
            md:text-start"
        >
            <span>
                {t(`${Translations.FOOTER}:branding`)}
            </span>
            {" "}
            <span>
                {t(`${Translations.FOOTER}:copyright`, {
                    currentYear: currentYear.toString(),
                })}
            </span>
        </p>
    );
}
