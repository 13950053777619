"use client";

import { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { motion } from "framer-motion";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";

export default function ThemeSwitcher() {
    const [mounted, setMounted] = useState<boolean>(false);
    const { theme, setTheme } = useTheme();

    useEffect(() => setMounted(true), []);
    if (!mounted) return null;

    const handleThemeChange = () => {
        setTheme(theme === "dark" ? "light" : "dark");
    };

    return (
        <section>
            <motion.button
                aria-label="Switch theme"
                onClick={handleThemeChange}
                whileTap={{ scale: 0.95 }}
                className="p-2.5 rounded-xl bg-neutral-200 
                dark:bg-neutral-800 hover:bg-neutral-300 
                dark:hover:bg-neutral-700 transition-all ease-in-out 
                duration-300"
            >
                {theme === "light" ? (
                    <SunIcon className="w-5 h-5" /> 
                ) : (
                    <MoonIcon className="w-5 h-5" />
                )}
            </motion.button>
        </section>
    );
}
