import { ImgHTMLAttributes } from "react";
import Image from "next/image";

const CDN_URL: string = (
    "https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3"
);

interface CountryFlagProps extends ImgHTMLAttributes<HTMLImageElement> {
    countryCode: string;
};

export default function CountryFlag(props: CountryFlagProps) {
    const { countryCode, width, height, ...imageProps } = props;
    const flagUrl: string = `${CDN_URL}/${countryCode.toLowerCase()}.svg`;

    return (
        <Image
            {...imageProps}
            src={flagUrl}
            alt={`${imageProps.alt || countryCode} flag`}
            width={20}
            height={15}
        />
    );
}
