"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";
import FooterLink, { FooterLinkProps } from "./FooterLink";

export interface FooterListProps {
    title: string;
    links: FooterLinkProps[];
};

export default function FooterList(props: FooterListProps) {
    const { t } = useTranslation(Translations.FOOTER);

    return (
        <section className="flex flex-col gap-3 w-fit">
            <h3
                className="flex flex-col font-bold text-xl text-neutral-950 
                md:text-2xl lg:leading-tight lg:text-3xl w-auto
                dark:text-neutral-50"
            >
                {t(props.title)}
            </h3>
            <ul className="flex flex-col gap-2 w-auto">
                {props.links.map((link, index) => 
                    <FooterLink
                        key={index}
                        href={link.href}
                        title={t(link.title)}
                        target={link.target}
                        email={link.email}
                    />
                )}
            </ul>
        </section>
    );
}
